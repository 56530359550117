<template>
  <div style="margin: 10px auto;width: 1200px;">
    <h1><i class="el-icon-s-data" style="color:rgb(48, 176, 143)"></i>阅读榜单</h1>
    <div class="cai-ni">
      <div class="cai-ni-box" v-for="(o, index) in hotList" :key="index">
        <div class="book-info" @click="openBookInfo(o.bookId)">
          <el-image
              style="width: 150px;height:210px;border-radius: 4px"
              fit="cover"
              :src="global.apiUri+o.cover"></el-image>
          <div class="right">
            <div style="width: 200px">
              <div class="book-title">{{ o.title }}</div>
              <p>{{ o.author }} 著</p>
              <p>分类：{{ o.cate }}</p>
              <p> 更新时间：{{ o.date }}</p>
            </div>
            <el-button type="text">立即阅读</el-button>
          </div>
        </div>
      </div>
    </div>
    <my-pagination ref="pagination" :currentPage="currentPage" :pageSize="pagesize" :dataUrl="dataUrl"
                   :searchData="searchData" @getData="getData"></my-pagination>

  </div>
</template>

<script>
import MyPagination from "../../components/pagination";

export default {
  name: "hot",

  components: {MyPagination},
  data() {
    return {
      hotList: [],
      currentPage: 1,
      pagesize: 10,
      searchData: {},
      dataUrl: '/ebook/read_top',
    }
  },
  created() {

  },
  methods: {
    //查询
    searchSubmit() {
      this.$refs.pagination.pages = 1;
      this.currentPage = 1;
      setTimeout(() => {
        //异步执行列表刷新
        this.$refs.pagination.getTableData();
      }, 0)
    },
    //表格获取数据
    getData(e) {
      this.hotList = e;
    },
    //更新表格数据
    updateList() {
      this.$refs.pagination.getTableData();
    },
    openBookInfo(bookId) {
      this.$router.push({path: '/book/bookInfo', query: {bookId: bookId}});
    }
  },
}
</script>

<style scoped lang="less">
.home {
  width: 100%;
}

h1 {
  font-size: 18px;
}

.cai-ni {
  width: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .cai-ni-box {
    width: 400px;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;

    .book-info {
      width: 380px;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      height: 210px;
      border-radius: 5px;
      border: 1px solid #E3DEDE74;
      box-sizing: border-box;
      font-size: 14px;
      cursor: pointer;

      .right {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .title {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>